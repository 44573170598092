<template>
  <div>
    <v-card-text>
      <v-select
        v-model="selectedTexte"
        outlined
        dense
        class="mt-3"
        :items="listTexts"
        :item-value="item => item.id"
        :item-text="item => item.title"
        @change="renderText"
      >
      </v-select>
    </v-card-text>
    <v-card-text v-if="selectedTexte === 0">
      <v-text-field
        v-model="newTextTitle"
        label="Titre du texte"
        outlined
        clearable
      ></v-text-field>
    </v-card-text>
    <v-card-text v-else>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="title"
            dense
            label="Titre du texte"
            outlined
            clearable
          ></v-text-field>
        </v-col>
        <v-col
          v-if="subjectEnabled"
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="emailSubject"
            dense
            label="Objet du mail"
            hint="La Formation Pro : Objet du mail - Nom Prénom"
            outlined
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text v-if="variablesEnabled">
      <TextesVariablesButtons @sendCode="addCode"></TextesVariablesButtons>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <v-textarea
        ref="area"
        v-model="displayText"
        rows="15"
        row-height="15"
      >
      </v-textarea>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-space-between"
        >
          <v-btn
            right
            color="error"
            outlined
            class="me-3 mt-4"
            @click.prevent="deleteText"
          >
            Supprimer
          </v-btn>
          <v-btn
            v-if="selectedTexte > 0"
            right
            color="primary"
            class="me-3 mt-4"
            @click.prevent="$emit('updateText', {id: selectedTexte, content : displayText, author: $store.state.user.id, title: title, emailSubject: emailSubject })"
          >
            Mettre à jour
          </v-btn>
          <v-btn
            v-else
            right
            color="primary"
            class="me-3 mt-4"
            @click.prevent="$emit('createText', {title: newTextTitle, content : displayText, author: $store.state.user.id, categorie: categorie})"
          >
            Enregistrer le texte
          </v-btn>
        </v-col>
        <v-row>
        </v-row>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>

import TextesVariablesButtons from './TextesVariablesButtons.vue'

export default {
  components: {
    TextesVariablesButtons,
  },
  props: {
    listTexts: {
      type: Array,
      default: () => [],
    },
    categorie: {
      type: String,
      default: () => 'type',
    },
    variablesEnabled: {
      type: Boolean,
      default: false,
    },
    subjectEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedTexte: 1,
      displayText: '',
      newTextTitle: '',
      title: '',
      emailSubject: '',
    }
  },
  created() {
    this.renderText(1)
  },
  methods: {
    deleteText() {
      this.$emit('deleteText', { id: this.selectedTexte })
    },
    renderText(a) {
      this.displayText = this.listTexts.find(x => x.id === a).content
      this.title = this.listTexts.find(x => x.id === a).title
      this.emailSubject = this.listTexts.find(x => x.id === a).emailSubject
    },
    addCode(payload) {
      let textVal = this.displayText
      const allLen = textVal.length
      const selectLen = this.$refs.area.$el.querySelector('textarea')
        .selectionStart
      const first = textVal.substr(0, selectLen)
      const insert = `[[${payload.toUpperCase()}]]`
      const latter = textVal.substr(selectLen, allLen)
      textVal = first + insert + latter
      this.displayText = textVal

      this.$nextTick(() => {
        this.$refs.area.focus()
        this.$refs.area.$el
          .querySelector('textarea')
          .setSelectionRange(selectLen + insert.length, selectLen + insert.length)
      })
    },
  },
}
</script>

<style>

</style>
