<template>
  <v-card>
    <v-card-title>
      Liste des textes
    </v-card-title>
    <v-divider></v-divider>
    <v-tabs v-model="tabs">
      <v-tabs-slider></v-tabs-slider>
      <v-tab>Textes types</v-tab>
      <v-tab>Textes de contact</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <TexteTypeEditor
          v-if="textes.length > 0"
          :categorie="'type'"
          :list-texts="listTexts.filter(x => x.categorie == 'type' || x.categorie == 'all')"
          :variables-enabled="true"
          :subject-enabled="true"
          @updateText="updateText"
          @createText="createText"
          @deleteText="deleteText"
        >
        </TexteTypeEditor>
        <v-skeleton-loader
          v-else
          v-bind="attrs"
          type="article, actions"
        ></v-skeleton-loader>
      </v-tab-item>
      <v-tab-item>
        <TexteTypeEditor
          v-if="textes.length > 0"
          :categorie="'contact'"
          :list-texts="listTexts.filter(x => x.categorie == 'contact' || x.categorie == 'all')"
          :variables-enabled="false"
          :subject-enabled="false"
          @updateText="updateText"
          @createText="createText"
          @deleteText="deleteText"
        ></TexteTypeEditor>
        <v-skeleton-loader
          v-else
          v-bind="attrs"
          type="article, actions"
        ></v-skeleton-loader>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import TexteTypeEditor from './TexteTypeEditor.vue'

export default {
  components: {
    TexteTypeEditor,
  },
  data() {
    return {
      tabs: null,
      textes: [],
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    }
  },
  computed: {
    listTexts() {
      const { textes } = this
      textes.push(
        {
          title: 'Nouveau Text',
          content: '',
          id: 0,
          categorie: 'all',
        },
      )
      textes.sort((a, b) => b.id - a.id)

      return textes
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$http.get(`${process.env.VUE_APP_API_URL}/textes`)
        .then(textes => {
          this.textes = textes.data
        })
        .catch(error => console.error(error))
    },
    updateText(payload) {
      this.$http.put(`${process.env.VUE_APP_API_URL}/textes/${payload.id}`, {
        content: payload.content,
        author: payload.author,
        title: payload.title,
        emailSubject: payload.emailSubject,
      })
        .then(updatedText => {
          const foundIndex = this.textes.findIndex(x => x.id === updatedText.data.id)
          this.textes[foundIndex] = updatedText.data

          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Texte mis à jour',
            value: true,
          })
        })
        .catch(error => {
          console.error(error)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la mise à jour',
            value: true,
          })
        })
    },
    createText(payload) {
      this.$http.post(`${process.env.VUE_APP_API_URL}/textes/`, {
        title: payload.title, content: payload.content, author: payload.author, categorie: payload.categorie,
      })
        .then(newText => {
          this.textes.push(newText.data)
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Nouveau texte ajouté',
            value: true,
          })
        })
        .catch(error => {
          console.error(error)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la création',
            value: true,
          })
        })
    },
    deleteText(payload) {
      this.$http.delete(`${process.env.VUE_APP_API_URL}/textes/${payload.id}`)
        .then(deletedText => {
          this.textes = this.textes.filter(x => x.id !== deletedText.data.id)
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Texte supprimé',
            value: true,
          })
        })
        .catch(error => {
          console.error(error)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la suppression',
            value: true,
          })
        })
    },

  },
}
</script>

<style>

</style>
